class ProductDetail {
    constructor(divContainerId) {
        if (divContainerId) {
            this.DivContainerId = divContainerId;
        } else {
            this.DivContainerId = document;
        }
    }

    quickView(code, productCode, listName, position, url) {
        var inst = this;
        $(inst.DivContainerId).find('.loading-box').show();
        axios.get(url, { params: { productCode: productCode, variantCode: code, listName, position } })
            .then(function (result) {
                if (result.status == 200) {
                    $('#quickView .modal-body').html(result.data);
                    $('#quickView .modal-body').off();
                    $(inst.DivContainerId).find("#productCode").val(productCode);
                    feather.replace();
                    var dropdown = new Dropdown("#quickView");
                    dropdown.Init();
                    var product = new Product('#quickView');
                    product.AddToCartClick();
                    product.AddToWishlistClick();
                    var productDetail = new ProductDetail('#quickView');
                    productDetail.SelectColorClick();
                    productDetail.SelectColorSizeClick();
                    productDetail.ChangeGygiOptions();
                    productDetail.ChangeImageClick();
                    productDetail.ZoomImage();
                    productDetail.YoutubeVideoModal();
                }
            })
            .catch(function (error) {
                $('#quickView .modal-body').html(error);
                $('#quickView .modal-body').addClass('p-5');
            })
            .finally(function () {
                $(inst.DivContainerId).find('.loading-box').hide();
            });
    }

    changeVariant(data, callback) {
        var inst = this;
        $(inst.DivContainerId).find('.loading-box').show();
        axios.get('/product/selectVariant', { params: data })
            .then(function (result) {
                if (callback) {
                    callback(result);
                    inst.SelectColorSizeClick(false, callback);
                    inst.SelectColorClick(false, callback);
                }
                else {
                    $(inst.DivContainerId).find('.modal-body').html(result.data);
                    $(inst.DivContainerId).find('.modal-body').off();
                    var dropdown = new Dropdown(inst.DivContainerId);
                    dropdown.Init();
                    var product = new Product(inst.DivContainerId);
                    product.AddToCartClick();
                    product.AddToWishlistClick();
                    var productDetail = new ProductDetail(inst.DivContainerId);
                    productDetail.ChangeQuantityKeyup();
                    productDetail.ChangeGygiOptions();
                    productDetail.GyGiCustomizationNotes();
                    productDetail.SelectColorClick();
                    productDetail.SelectColorSizeClick();
                    productDetail.ChangeImageClick();
                    productDetail.ZoomImage();
                    productDetail.YoutubeVideoModal();

                    var url = $(inst.DivContainerId).find("#variantUrl").val();
                    window.history.pushState({ "html": result.data }, "", url);
                }
            })
            .catch(function (error) {
                notification.Error(error);
                $('#quickView .modal-body').html('');
            })
            .finally(function () {
                $(inst.DivContainerId).find('.loading-box').hide();
            });
    }

    InStorePickupClick() {
        var inst = this;
        $(this.DivContainerId).find('.jsSelectDelivery').each(function (i, e) {
            $(e).click(function () {
                var valueChecked = $(this).find('input').first().val();
                $(inst.DivContainerId).find('.addToCart').attr('store', valueChecked);
                $(inst.DivContainerId).find('.jsBuyNow').attr('store', valueChecked);
                if (valueChecked === 'instore') {
                    var selectedStore = $(inst.DivContainerId).find('#selectedStore').val();
                    $(inst.DivContainerId).find('.addToCart').attr('selectedStore', selectedStore);
                    $(inst.DivContainerId).find('.jsBuyNow').attr('selectedStore', selectedStore);
                    if (!$(inst.DivContainerId).find('#pickupStoreBox').is(':visible')) {
                        $(inst.DivContainerId).find('#pickupStoreBox').fadeToggle();
                    }
                } else {
                    $(inst.DivContainerId).find('.addToCart').attr('selectedStore', '');
                    $(inst.DivContainerId).find('.jsBuyNow').attr('selectedStore', '');
                    if ($(inst.DivContainerId).find('#pickupStoreBox').is(':visible')) {
                        $(inst.DivContainerId).find('#pickupStoreBox').fadeOut(300);
                    }
                }
            });
        });
    }

    SelectStoreClick() {
        var inst = this;
        $(this.DivContainerId).find('.jsSelectStore').each(function (i, e) {
            $(e).click(function () {
                var storeCode = $(this).attr('data');
                $(inst.DivContainerId).find('#selectedStore').val(storeCode);
                $(inst.DivContainerId).find('.selectedStoreIcon').each(function (j, s) {
                    $(s).hide();
                });
                $(inst.DivContainerId).find('.jsSelectStore').each(function (j, s) {
                    $(s).show();
                });

                $(this).hide();
                $(this).siblings('.selectedStoreIcon').show();

                $(inst.DivContainerId).find('.addToCart').attr('selectedStore', storeCode);
                $(inst.DivContainerId).find('.jsBuyNow').attr('selectedStore', storeCode);
            });
        });
    }

    SelectColorSizeClick(isQuickView, callback) {
        var inst = this;
        $(this.DivContainerId).find(".jsSelectColorSize").each(function (i, e) {
            $(e).unbind().change(function (event) {
                var changedOption = $(event.target).attr("name");
                var size = $(inst.DivContainerId).find("select[name='size']").val();
                var style = $(inst.DivContainerId).find("select[name='style']").val();
                var productCode = $(inst.DivContainerId).find("#productCode").val();
                var data = { productCode: productCode, size: size, style: style, changedOption: changedOption, isQuickView: isQuickView };
                inst.changeVariant(data, callback, isQuickView);
            });
        });

    }

    SelectColorClick(isQuickView, callback) {
        var inst = this;
        $(this.DivContainerId).find(".colorSwatch").each(function (i, e) {

            $(e).unbind().click(function (event) {
                var changedOption = "Color";
                var color = event.target.dataset.color;
                var productCode = $(inst.DivContainerId).find("#productCode").val();
                var data = { productCode: productCode, color: color, changedOption: changedOption, isQuickView: isQuickView };
                inst.changeVariant(data, callback);

            });
        });
    }

    ChangeQuantityKeyup() {


        $('input[name=qty]').on('change', function () {
            let qty = $(this);
            qty.closest('.adToCart, .jsBuyNow').attr('qty', qty.val());

        });
    }

    ChangeGygiOptions() {
        $('.jsChangeGygiOptions').on('change', function () {
            var option = $(this).val();
            $('#dropdownGygiOption').text(option);

            if (option === "Add custom text + $10") {
                $('#gygiCustomMessage').show();
            } else {
                $('#gygiNote').val("");
                $('#gygiCustomMessage').hide();
                $('.addToCart').attr('gygiNote', "");
                $('.jsBuyNow').attr('gygiNote', "");
            }

            $('.addToCart').attr('gygi', option);
            $('.jsBuyNow').attr('gygi', option);
        });
    }

    GyGiCustomizationNotes() {
        $(document).on('focusout', '#gygiNote', function () {
            var note = $(this).val();
            $('.addToCart').attr('gygiNote', note);
            $('.jsBuyNow').attr('gygiNote', note);
        });
    }

    ChangeImageClick() {
        var img = '.activeListImage';
        var firstImgList = $(img).first()[0];
        $(firstImgList).addClass('active');
        $(this.DivContainerId).find('.jsProductImageSelect').each(function (i, e) {
            var selector = '.activeImage';
            var firstImg = $(selector).first()[0];
            $(firstImg).addClass('active');
            $(selector).on('click', function () {
                $(selector).removeClass('active');
                $(this).addClass('active');
            });
            $(e).on('click', function () {
                $(".activeListImage").removeClass('active');
                $(this).addClass('active');
            });
            $(e).click(function () {
                var type = "Image";
                var mediaTag = $(this).find('img');
                if (!mediaTag.is(":visible")) {
                    var type = "Video";
                    mediaTag = $(this).find('video');
                }
                var urlImg = mediaTag.attr('src');
                if (type == "Image") {
                    $('.jsProductImageShow').find('img').attr('src', urlImg);
                    $('.jsProductImageShow').find('img').css("display", "inline");
                    $('.jsProductImageShow').find('video').css("display", "none");
                    $('.zoomImg').attr('src', urlImg);
                    $('.zoomImg').attr('alt', "Product Image");
                    // set height and width to 2250px using the rendered sizes on the inline styles attribute
                    $('.zoomImg').css('height', '2250px');
                    $('.zoomImg').css('width', '2250px');

                } else {
                    $('.jsProductImageShow').find('video').attr('src', urlImg);
                    $('.jsProductImageShow').find('img').css("display", "none");
                    $('.jsProductImageShow').find('video').css("display", "inline");
                }

            });
        });
    }

    ZoomImage() {
        $(this.DivContainerId).find('.jsProductImageShow').each(function (i, e) {
            if ($(e).find('img').is(":visible")) {
                var urlImg = $(e).find('img').attr('src');
                $(e).siblings('div').first().children('div').first().zoom({
                    url: urlImg,
                    magnify: 1.5,
                    onZoomIn: true,
                    onZoomOut: true
                });
            }

        });
    }

    BuyNowClick() {
        $(this.DivContainerId).find('.jsBuyNow').each(function (i, e) {
            $(e).click(async function () {
                $('.loading-box').show();
                var code = $(this).attr('data');
                var data = {
                    Code: code
                };

                if ($(this).attr('qty')) data.Quantity = $(this).attr('qty');
                if ($(this).attr('store')) data.Store = $(this).attr('store');
                if ($(this).attr('selectedStore')) data.SelectedStore = $(this).attr('selectedStore');
                var url = $(this).attr('url');

                try {
                    const r = await axios.post(url, data);
                    if (r.data.Message) {
                        notification.Error(r.data.Message);
                        setTimeout(function () {
                            window.location.href = r.data.Redirect;
                        }, 1000);
                    } else {
                        window.location.href = r.data.Redirect;
                    }
                } catch (e) {
                    notification.Error(e);
                } finally {
                    $('.loading-box').hide();
                }
            })
        })
    }

    YoutubeVideoModal() {
        const inst = this;
        const videoModal = $(inst.DivContainerId).find('#videoModal');
        videoModal && videoModal.on('hide.bs.modal', function () {
            let src = $(this).find('iframe').attr('src');
            $(this).find('iframe').attr('src', src);
        });
    }
    

    InitProductDetail() {
        var inst = this;
        this.InStorePickupClick();
        this.SelectStoreClick();
        this.ChangeGygiOptions();
        this.GyGiCustomizationNotes();

        this.SelectColorSizeClick(false, function (result) {
            if (result.status == 200) {
                var breadCrumb = $('.bread-crumb').html();
                var review = $('.jsReviewRating').html();
                $(inst.DivContainerId).html(result.data);
                $('.bread-crumb').html(breadCrumb);
                $('.jsReviewRating').html(review);
                $(inst.DivContainerId).off();
                $(inst.DivContainerId).val(productCode);
                feather.replace();
                var dropdown = new Dropdown(inst.DivContainerId);
                dropdown.Init();
                var product = new Product(inst.DivContainerId);
                product.Init();
                inst.ChangeQuantityKeyup();
                inst.ChangeGygiOptions();
                inst.GyGiCustomizationNotes();
                inst.InStorePickupClick();
                inst.SelectStoreClick();
                inst.ChangeImageClick();
                inst.ZoomImage();
                inst.BuyNowClick();
                inst.YoutubeVideoModal();

                var url = $(inst.DivContainerId).find("#variantUrl").val();
                window.history.pushState({ "html": result.data }, "", url);
            }
        }
        );
        this.SelectColorClick(false, function (result) {
            if (result.status == 200) {
                var breadCrumb = $('.bread-crumb').html();
                var review = $('.jsReviewRating').html();
                $(inst.DivContainerId).html(result.data);
                $('.bread-crumb').html(breadCrumb);
                $('.jsReviewRating').html(review);
                $(inst.DivContainerId).off();
                $(inst.DivContainerId).val(productCode);
                feather.replace();
                var dropdown = new Dropdown(inst.DivContainerId);
                dropdown.Init();
                var product = new Product(inst.DivContainerId);
                product.Init();
                inst.ChangeQuantityKeyup();
                inst.ChangeGygiOptions();
                inst.GyGiCustomizationNotes();
                inst.InStorePickupClick();
                inst.SelectStoreClick();
                inst.ChangeImageClick();
                inst.ZoomImage();
                inst.BuyNowClick();
                inst.YoutubeVideoModal();

                var url = $(inst.DivContainerId).find("#variantUrl").val();
                window.history.pushState({ "html": result.data/*, "pageTitle": response.pageTitle */ }, "", url);
            }
        }
        );
        this.ZoomImage();
        this.ChangeImageClick();
        this.BuyNowClick();
        this.YoutubeVideoModal();

    }

    InitQuickView() {
        var inst = this;
        // Init quickview
        $('.jsQuickView').each(function (i, e) {
            $(e).click(() => {
                var code = $(this).attr('data');
                var productCode = $(this).attr('productCode');
                var listName = $(this).attr('data-list-name');
                var position = $(this).attr('data-position');
                var url = $(this).attr('urlQuickView');
                if (url == undefined || url == "") {
                    url = "/product/quickview";
                }

                inst.quickView(code, productCode, listName, position, url);
            });
        });
        //-- end
    }

    BuildGa4Object(dataset) {
        return {
            item_name: dataset.item_name || '',
            item_id: dataset.item_id || '',
            price: dataset.price || '',
            item_brand: dataset.item_brand || '',
            item_category: dataset.item_category || '',
            item_category2: dataset.item_category2 || '',
            item_category3: dataset.item_category3 || '',
            item_category4: dataset.item_category4 || '',
            item_category5: dataset.item_category5 || '',
            item_variant: dataset.item_variant || '',
            item_list_name: dataset.item_list_name || '',
            item_list_id: dataset.item_list_id || '',
            index: dataset.index || '',
            quantity: dataset.quantity || ''
        }
    }

    BuildGa4ObjectViewItem(dataset) {
        return {
            item_name: dataset.item_name || '',
            item_id: dataset.item_id || '',
            price: dataset.price || '',
            item_brand: dataset.item_brand || '',
            item_category: dataset.item_category || '',
            item_category2: dataset.item_category2 || '',
            item_category3: dataset.item_category3 || '',
            item_category4: dataset.item_category4 || '',
            item_category5: dataset.item_category5 || '',
            item_variant: dataset.item_variant || '',
            quantity: dataset.quantity || ''
        }
    }
    
    InitJsGa4() {
        var inst = this;
        var observer = new IntersectionObserver(function (entries) {
            var items = []
            entries.forEach(function (entry) {
                if (entry.isIntersecting && !entry.target.classList.contains('viewed')) {
                    items.push(inst.BuildGa4Object(entry.target.dataset))
                    entry.target.classList.add('viewed');
                }
            })
            
            if (items.length) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'view_item_list',
                    ecommerce: {
                        items
                    }
                });
            }

        }, { root: null, threshold: [0.75] }); // 0.75 => when a 75% of a product is intersecting the viewport

        document.querySelectorAll('.jsGa4 .jsGa4-item-list').forEach((element) => {
            if (element) {
                // GA4 View items on a list
                observer.observe(element);

                // GA4 Select item from a list
                $(element).find('.product-thumbnail__image-wrapper, .product-thumbnail__name').click(function (e) {
                    var item = inst.BuildGa4Object(e.currentTarget.closest('.jsGa4-item-list').dataset)
                    
                    if (Object.keys(item).length) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: 'select_item',
                            ecommerce: {
                                items: [item]
                            }
                        });
                    }
                })
            }
        });

        // GA4 View Item
        var prod = document.querySelector('.jsGa4-product-detail')

        if (prod) {
            var item = inst.BuildGa4ObjectViewItem(prod.dataset)
  
            if (Object.keys(item).length) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'view_item',
                    ecommerce: {
                        items: [item]
                    }
                });
            }
        }

        //GA4 Select Item from outside a list
        $('.product-thumbnail_link, .product-thumbnail_image').click(function (e) {
            var item = inst.BuildGa4Object(e.currentTarget.closest(".jsGa4-select-item").dataset)
            
            if (Object.keys(item).length) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'select_item',
                    ecommerce: {
                        items: [item]
                    }
                });
            }
        })
    }
}